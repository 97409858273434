<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar :title="'我的'+blockName" left-arrow @click-left="$router.back()">
        <template #right  v-if="pagePara.filterData.blockId==0">
          <div @click="tolink()">
            <div style="float: right; margin-left: 5px; font-size: 15px">
              规则
            </div>
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <h4 style="padding: 2px 1px 5px 18px">可用{{blockName}}</h4>
    <div style="display: table; width: 100%">
      <div
        @click="toeshop()"
        style="
          float: left;
          padding: 2px 1px 5px 18px;
          margin: 10px 0px 10px 30px;
        "
      >
        <h2>{{ myScore }}</h2>
        <div style="float: right">
          <van-tag round color="#9d1d22" size="medium">点击兑换</van-tag>
        </div>
      </div>

      <div style="float: right">
        <van-cell :to="'/score/rank?blockId='+pagePara.filterData.blockId" is-link>
          <h3 style="text-align: center; margin-left: 24px; color: #9d1d22">
            {{blockName}}排行
          </h3>
        </van-cell>
      </div>
    </div>
    <h4 style="padding: 2px 1px 5px 18px" v-if="pagePara.filterData.blockId==0" >学习积分累计：{{courseScore}}</h4>
    <h4 style="padding: 2px 1px 5px 18px">{{blockName}}列表</h4>
    <van-list
      v-model="upLoading"
      :finished="finished"
      @load="onLoad"
      finished-text="没有更多了"
    >
      <van-cell-group>
        <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
        <van-cell v-for="item in articles" :key="item.id">
          <van-col span="24">
            <van-col span="14">
              {{ item.remark }}
            </van-col>
            <van-col span="4">
              <h3 style="display: inline-block">
                {{ item.score > 0 ? "+" + item.score : item.score }}
              </h3>
            </van-col>
            <van-col span="6">
              <div style="float: right">{{ item.creationTime }}</div>
            </van-col>
          </van-col>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import { getMyScoreList, GetMyScore } from "@/api/score"; //,GetMyScore

//const key = "zn-history";
export default {
  name: "myScore",
  data() {
    return {
      blockId:0,
      myScore: 0,
      courseScore: 0,
      blockName:"",
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      pagePara: {
        current: 1,
        pageSize: 20,
        filterData: {
          blockId:0
        },
      },
    };
  },
  components: {},
  created() {
    this.initData();
  },
  watch: {
    $route(to) {
      //监听路由是否变化
      if (to.path == "/user/myscore") {
     
        this.initData();
        this.onSearch();
      }
    },
  },
  mounted() {},

  methods: {
    async initData() {

     this.blockId=this.$route.query.blockId;
     this.pagePara.filterData.blockId=this.$route.query.blockId;
     if(this.pagePara.filterData.blockId==0){
      this.blockName='积分';
     }
     else if(this.pagePara.filterData.blockId==1){
      this.blockName='能量值';
     }
     


      let aresult = await GetMyScore(this.$route.query.blockId);
      this.myScore = aresult.data.data.canUseScore;
      this.courseScore = aresult.data.data.courseScore;
    },

    onSearch() {
      this.articles = [];
      this.pagePara.current = 1;
      this.pagePara.pageSize = 20;
      this.onLoad();
    },
    tolink() {
      this.$router.push({
        path: "/article/detail?para=1d6f5c38-235a-4664-a42c-a6dc393b4dc3&type=104",
      });
    },
    toeshop() {
      this.$router.push({ path: "/eshop", query: { blockId: this.pagePara.filterData.blockId }});
    },

    async onLoad() {
      let aresult = await getMyScoreList({ ...this.pagePara });

      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.pagePara.current * this.pagePara.pageSize < aresult.data.total) {
        this.pagePara.current++;
        this.finished = false;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
